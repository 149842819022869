@use "@picocss/pico/scss/pico" with (
  $theme-color: "yellow",
  $modules: (
    "content/code": false,
    "forms/input-color": false,
    "forms/input-date": false,
    "forms/input-file": false,
    "forms/input-range": false,
    "forms/input-search": false,
    "components/accordion": false,
    "components/card": true,
    "components/dropdown": false,
    "components/loading": false,
    "components/modal": false,
    "components/nav": true,
    "components/progress": false,
    "components/tooltip": false,
    "utilities/accessibility": false,
    "utilities/reduce-motion": false
  )
);

$md: 768px;

:root {
  --pico-border-radius: 0;
  --md: #{$md};
  --text-block-gradient: linear-gradient(155deg, rgba(222,222,222,.2) 0%, rgba(222,222,222,0) 100%);
}

html {
  scroll-behavior: smooth;
}

[data-theme="light"],
:root:not([data-theme="dark"]) {
  --pico-background-color: #fefefe;
  --pico-box-shadow: 1px 1px 3px #00000030, 2px 3px 5px 1px #00000020;
  --pico-blockquote-border-color: var(--pico-primary-background);
  --pico-h3-color: #717171;
}

/* inter-latin-400-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(../assets/inter-latin-900-normal.woff2) format('woff2');
}

@font-face {
  font-family: 'Roit';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(../assets/protest-riot-latin-400-normal.woff2) format('woff2');
}

@keyframes opacity {
  0%   {opacity: 0}
  100% {opacity: 1}
}

.text-box {
  position: relative;
  z-index: 1;
  background: var(--text-block-gradient);
  padding: 1.5rem;
  padding-top: 2.5rem;
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 20%;
    height: .4rem;
    background: var(--pico-primary-background);
  }
}

h1, h2 {
  --pico-font-family: 'Inter', sans-serif; 
  --pico-font-weight: 900;
}

@media (min-width: $md) {
  h1 {
    --pico-font-size: 2.5rem;
  }
}

h2 {
  --pico-font-size: 2rem;
  padding-top: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  a {
    --pico-color: var(--pico-primary-background);
    --pico-primary-hover: var(--pico-primary-background);
    --pico-text-decoration: none !important;
    opacity: 0.25;
  }
  &:hover a {
    opacity: 1;
  }
}

h3 {
  font-family: 'Roit';
  --pico-font-weight: 400;
}

blockquote {
  background: var(--text-block-gradient);
  border-left-width: .4rem;
}

svg.bi {
  width: 1em;
  height: 1em;
}

body {
}

body[is-opened] {
  overflow: hidden;
}

.page-wrap {
  position: relative;
  z-index: 2;
}

header.page-header {
  --pico-block-spacing-vertical: 0;
  background-color: var(--pico-secondary);
  background-image: url(../assets/markus-spiske-d7FbDJkJSFw-unsplash_hd.jpg?as=webp&width=1200&quality=30);
  background-attachment: fixed;
  background-size: cover;
  height: 35rem;
  > div {
    background-color: var(--pico-background-color);
  }
  nav {
    width: calc(100% - 3.5rem);
    margin: auto;
    a {
      --pico-font-family: 'Inter', sans-serif; 
    }
  }
  h1 {
    text-align: center;
    padding: 0 0 3rem;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: var(--pico-primary-background);
    text-decoration-thickness: .3rem;
    text-underline-offset: 1rem;
    line-height: 4.5rem;
  }
  .logo {
    padding: 1rem 0 0;
    img {
      height: 6.5rem;
    }
  }
}

// ######################################
//   fixed-nav .logo
// ######################################

header nav.fixed-nav .logo {
  position: fixed;
  z-index: 100;
  display: none;
}

[is-opened] nav.fixed-nav .logo {
  display: flex;
  opacity: 0;
  animation-name: opacity;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

// ######################################
//   MENU
// ######################################

#menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  z-index: -1;
  border-radius: 100%;
  border-top-right-radius: 0;
  transform: translate(100vw, -100vh);
  transition: border-radius 0.5s, transform 0.3s;
  background: var(--pico-background-color);
  .item {
    opacity: 0;
    transition: transform 0.8s, opacity 0.5s;
    transform: translateX(-25%);
    transition-timing-function: ease-out;
    transition-delay: 0.3s;
  }
}

[is-opened] #menu {
  z-index:  99;
  transform: translate(0,0);
  border-radius: 0;
  .item {
    opacity: 1;
    transform: translateX(0);
  }
}

#menu-btn {
  position: fixed;
  top: 0;
  left: calc(90% - 1.85rem);
  z-index: 100;
  button {
    font-size: 1.35rem;
  }
  svg {
    width: 1em;
    height: 1em;
    transition: transform 0.3s;
    transform: rotate(136deg);
  }
}

[is-opened] #menu-btn {
  svg {
    width: 1em;
    transform: rotate(-45deg);
  }
}


// ######################################
//   Main Content
// ######################################

main {
  overflow: hidden;
  background-color: var(--pico-background-color);
  // box-shadow: 0 1px 3px #00000050, 0 3px 3px #00000035;
  box-shadow: var(--pico-box-shadow);
  > section:last-child {
    margin-bottom: 0;
  }
}

section.leistungen {
  > div {
    display: flex;
    gap: 2rem;
    margin-bottom: 3.5rem;
    @media (max-width: $md) {
      display: block;
      // background: tomato;
    }
    @media (min-width: $md) {
    }
  }
  .img {
    position: relative;
    z-index: 1;
    flex: 1;
    img {
      position: relative;
      display: block;
      margin: auto;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: -4rem;
      left: -2rem;
      width: 20rem;
      height: 20rem;
      border: 3px dotted var(--pico-primary-background);
      border-radius: 50%;
    }
  }
  .text {
    @extend .text-box;
    flex: 1.68 1 0;
  }
}

section.partner {
  .grid > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
}

section.about {
  margin-bottom: 7rem;
}

section.map {
  h2 {
    position: absolute;
    margin: auto;
    text-align: center;
    width: 100%;
    padding-top: 3rem;
  }
  img {
    display: block;
    margin: auto;
  }
}

footer.page-footer {
  position: sticky;
  bottom: 0;
  z-index: -1;
  padding: 3rem 0;
  background-color: var(--pico-secondary);
  background-image: url(../assets/markus-spiske-d7FbDJkJSFw-unsplash_hd.jpg?as=webp&width=1200&quality=30);
  // background-image: url(../assets/solar1.jpg?as=webp&width=1200);
  background-size: cover;
  text-align: center;
  article {
    display: inline-block;
  }
  h2 {
    padding-top: unset;
  }
  .media {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.5rem;
    font-size: 1.35rem;
  }
}

[data-inviewport="slide-left"] {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in;
  &.inViewport-init {
    opacity: 0;
    transform: translateX(-18px);
  }
  &.is-inViewport {
    opacity: 1;
    transform: translateX(1);
  }
}

[data-inviewport="slide-right"] {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in;
  &.inViewport-init {
    opacity: 0;
    transform: translateX(18px);
  }
  &.is-inViewport {
    opacity: 1;
    transform: translateX(1);
  }
}

