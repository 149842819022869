:root {
  --pico-font-family-emoji: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --pico-font-family-sans-serif: system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, Arial, "Helvetica Neue", sans-serif, var(--pico-font-family-emoji);
  --pico-font-family-monospace: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace, var(--pico-font-family-emoji);
  --pico-font-family: var(--pico-font-family-sans-serif);
  --pico-line-height: 1.5;
  --pico-font-weight: 400;
  --pico-font-size: 100%;
  --pico-text-underline-offset: .1rem;
  --pico-border-radius: .25rem;
  --pico-border-width: .0625rem;
  --pico-outline-width: .125rem;
  --pico-transition: .2s ease-in-out;
  --pico-spacing: 1rem;
  --pico-typography-spacing-vertical: 1rem;
  --pico-block-spacing-vertical: var(--pico-spacing);
  --pico-block-spacing-horizontal: var(--pico-spacing);
  --pico-grid-column-gap: var(--pico-spacing);
  --pico-grid-row-gap: var(--pico-spacing);
  --pico-form-element-spacing-vertical: .75rem;
  --pico-form-element-spacing-horizontal: 1rem;
  --pico-group-box-shadow: 0 0 0 #0000;
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
  --pico-group-box-shadow-focus-with-input: 0 0 0 .0625rem var(--pico-form-element-border-color);
  --pico-nav-element-spacing-vertical: 1rem;
  --pico-nav-element-spacing-horizontal: .5rem;
  --pico-nav-link-spacing-vertical: .5rem;
  --pico-nav-link-spacing-horizontal: .5rem;
  --pico-nav-breadcrumb-divider: ">";
  --pico-icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --pico-icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

@media (width >= 576px) {
  :root {
    --pico-font-size: 106.25%;
  }
}

@media (width >= 768px) {
  :root {
    --pico-font-size: 112.5%;
  }
}

@media (width >= 1024px) {
  :root {
    --pico-font-size: 118.75%;
  }
}

@media (width >= 1280px) {
  :root {
    --pico-font-size: 125%;
  }
}

@media (width >= 1536px) {
  :root {
    --pico-font-size: 131.25%;
  }
}

a, a.secondary, a.contrast {
  --pico-text-decoration: underline;
}

small {
  --pico-font-size: .875em;
}

h1, h2, h3, h4, h5, h6 {
  --pico-font-weight: 700;
}

h1 {
  --pico-font-size: 2rem;
  --pico-line-height: 1.125;
  --pico-typography-spacing-top: 3rem;
}

h2 {
  --pico-font-size: 1.75rem;
  --pico-line-height: 1.15;
  --pico-typography-spacing-top: 2.625rem;
}

h3 {
  --pico-font-size: 1.5rem;
  --pico-line-height: 1.175;
  --pico-typography-spacing-top: 2.25rem;
}

h4 {
  --pico-font-size: 1.25rem;
  --pico-line-height: 1.2;
  --pico-typography-spacing-top: 1.874rem;
}

h5 {
  --pico-font-size: 1.125rem;
  --pico-line-height: 1.225;
  --pico-typography-spacing-top: 1.6875rem;
}

h6 {
  --pico-font-size: 1rem;
  --pico-line-height: 1.25;
  --pico-typography-spacing-top: 1.5rem;
}

thead th, thead td, tfoot th, tfoot td {
  --pico-font-weight: 600;
  --pico-border-width: .1875rem;
}

input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]), :where(select, textarea) {
  --pico-outline-width: .0625rem;
}

[type="search"] {
  --pico-border-radius: 5rem;
}

[type="checkbox"], [type="radio"] {
  --pico-border-width: .125rem;
}

[type="checkbox"][role="switch"] {
  --pico-border-width: .1875rem;
}

[role="search"] {
  --pico-border-radius: 5rem;
}

[role="search"]:has(button.secondary:focus, [type="submit"].secondary:focus, [type="button"].secondary:focus, [role="button"].secondary:focus), [role="group"]:has(button.secondary:focus, [type="submit"].secondary:focus, [type="button"].secondary:focus, [role="button"].secondary:focus) {
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[role="search"]:has(button.contrast:focus, [type="submit"].contrast:focus, [type="button"].contrast:focus, [role="button"].contrast:focus), [role="group"]:has(button.contrast:focus, [type="submit"].contrast:focus, [type="button"].contrast:focus, [role="button"].contrast:focus) {
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-contrast-focus);
}

[role="search"] button, [role="search"] [type="submit"], [role="search"] [type="button"], [role="search"] [role="button"], [role="group"] button, [role="group"] [type="submit"], [role="group"] [type="button"], [role="group"] [role="button"] {
  --pico-form-element-spacing-horizontal: 2rem;
}

[data-theme="light"], :root:not([data-theme="dark"]) {
  --pico-background-color: #fff;
  --pico-color: #373c44;
  --pico-text-selection-color: #9e920040;
  --pico-muted-color: #646b79;
  --pico-muted-border-color: #e7eaf0;
  --pico-primary: #756b00;
  --pico-primary-background: #f2df0d;
  --pico-primary-border: var(--pico-primary-background);
  --pico-primary-underline: #756b0080;
  --pico-primary-hover: #5b5300;
  --pico-primary-hover-background: #e8d600;
  --pico-primary-hover-border: var(--pico-primary-hover-background);
  --pico-primary-hover-underline: var(--pico-primary-hover);
  --pico-primary-focus: #9e920080;
  --pico-primary-inverse: #000;
  --pico-secondary: #5d6b89;
  --pico-secondary-background: #525f7a;
  --pico-secondary-border: var(--pico-secondary-background);
  --pico-secondary-underline: #5d6b8980;
  --pico-secondary-hover: #48536b;
  --pico-secondary-hover-background: #48536b;
  --pico-secondary-hover-border: var(--pico-secondary-hover-background);
  --pico-secondary-hover-underline: var(--pico-secondary-hover);
  --pico-secondary-focus: #5d6b8940;
  --pico-secondary-inverse: #fff;
  --pico-contrast: #181c25;
  --pico-contrast-background: #181c25;
  --pico-contrast-border: var(--pico-contrast-background);
  --pico-contrast-underline: #181c2580;
  --pico-contrast-hover: #000;
  --pico-contrast-hover-background: #000;
  --pico-contrast-hover-border: var(--pico-contrast-hover-background);
  --pico-contrast-hover-underline: var(--pico-secondary-hover);
  --pico-contrast-focus: #5d6b8940;
  --pico-contrast-inverse: #fff;
  --pico-box-shadow: .0145rem .029rem .174rem #8191b504, .0335rem .067rem .402rem #8191b506, .0625rem .125rem .75rem #8191b508, .1125rem .225rem 1.35rem #8191b509, .2085rem .417rem 2.502rem #8191b50b, .5rem 1rem 6rem #8191b50f, 0 0 0 .0625rem #8191b504;
  --pico-h1-color: #2d3138;
  --pico-h2-color: #373c44;
  --pico-h3-color: #424751;
  --pico-h4-color: #4d535e;
  --pico-h5-color: #5c6370;
  --pico-h6-color: #646b79;
  --pico-mark-background-color: #fde7c0;
  --pico-mark-color: #0f1114;
  --pico-ins-color: #1d6a54;
  --pico-del-color: #883935;
  --pico-blockquote-border-color: var(--pico-muted-border-color);
  --pico-blockquote-footer-color: var(--pico-muted-color);
  --pico-button-box-shadow: 0 0 0 #0000;
  --pico-button-hover-box-shadow: 0 0 0 #0000;
  --pico-table-border-color: var(--pico-muted-border-color);
  --pico-table-row-stripped-background-color: #6f78870a;
  --pico-form-element-background-color: #fbfcfc;
  --pico-form-element-selected-background-color: #dfe3eb;
  --pico-form-element-border-color: #cfd5e2;
  --pico-form-element-color: #23262c;
  --pico-form-element-placeholder-color: var(--pico-muted-color);
  --pico-form-element-active-background-color: #fff;
  --pico-form-element-active-border-color: var(--pico-primary-border);
  --pico-form-element-focus-color: var(--pico-primary-border);
  --pico-form-element-disabled-opacity: .5;
  --pico-form-element-invalid-border-color: #b86a6b;
  --pico-form-element-invalid-active-border-color: #c84f48;
  --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
  --pico-form-element-valid-border-color: #4c9b8a;
  --pico-form-element-valid-active-border-color: #279977;
  --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
  --pico-switch-background-color: #bfc7d9;
  --pico-switch-checked-background-color: var(--pico-primary-background);
  --pico-switch-color: #fff;
  --pico-switch-thumb-box-shadow: 0 0 .5rem #00000040;
  --pico-card-background-color: var(--pico-background-color);
  --pico-card-border-color: var(--pico-muted-border-color);
  --pico-card-box-shadow: var(--pico-box-shadow);
  --pico-card-sectioning-background-color: #fbfcfc;
  --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(76, 155, 138)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(200, 79, 72)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  color-scheme: light;
}

[data-theme="light"] input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]), :root:not([data-theme="dark"]) input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
  --pico-form-element-focus-color: var(--pico-primary-focus);
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --pico-background-color: #13171f;
    --pico-color: #c2c7d0;
    --pico-text-selection-color: #ad9f0030;
    --pico-muted-color: #7b8495;
    --pico-muted-border-color: #202632;
    --pico-primary: #ad9f00;
    --pico-primary-background: #f2df0d;
    --pico-primary-border: var(--pico-primary-background);
    --pico-primary-underline: #ad9f0080;
    --pico-primary-hover: #caba01;
    --pico-primary-hover-background: #f8e861;
    --pico-primary-hover-border: var(--pico-primary-hover-background);
    --pico-primary-hover-underline: var(--pico-primary-hover);
    --pico-primary-focus: #ad9f0060;
    --pico-primary-inverse: #000;
    --pico-secondary: #969eaf;
    --pico-secondary-background: #525f7a;
    --pico-secondary-border: var(--pico-secondary-background);
    --pico-secondary-underline: #969eaf80;
    --pico-secondary-hover: #b3b9c5;
    --pico-secondary-hover-background: #5d6b89;
    --pico-secondary-hover-border: var(--pico-secondary-hover-background);
    --pico-secondary-hover-underline: var(--pico-secondary-hover);
    --pico-secondary-focus: #909ebe40;
    --pico-secondary-inverse: #fff;
    --pico-contrast: #dfe3eb;
    --pico-contrast-background: #eff1f4;
    --pico-contrast-border: var(--pico-contrast-background);
    --pico-contrast-underline: #dfe3eb80;
    --pico-contrast-hover: #fff;
    --pico-contrast-hover-background: #fff;
    --pico-contrast-hover-border: var(--pico-contrast-hover-background);
    --pico-contrast-hover-underline: var(--pico-contrast-hover);
    --pico-contrast-focus: #cfd5e240;
    --pico-contrast-inverse: #000;
    --pico-box-shadow: .0145rem .029rem .174rem #07090c04, .0335rem .067rem .402rem #07090c06, .0625rem .125rem .75rem #07090c08, .1125rem .225rem 1.35rem #07090c09, .2085rem .417rem 2.502rem #07090c0b, .5rem 1rem 6rem #07090c0f, 0 0 0 .0625rem #07090c04;
    --pico-h1-color: #f0f1f3;
    --pico-h2-color: #e0e3e7;
    --pico-h3-color: #c2c7d0;
    --pico-h4-color: #b3b9c5;
    --pico-h5-color: #a4acba;
    --pico-h6-color: #8891a4;
    --pico-mark-background-color: #014063;
    --pico-mark-color: #fff;
    --pico-ins-color: #62af9a;
    --pico-del-color: #ce7e7b;
    --pico-blockquote-border-color: var(--pico-muted-border-color);
    --pico-blockquote-footer-color: var(--pico-muted-color);
    --pico-button-box-shadow: 0 0 0 #0000;
    --pico-button-hover-box-shadow: 0 0 0 #0000;
    --pico-table-border-color: var(--pico-muted-border-color);
    --pico-table-row-stripped-background-color: #6f78870a;
    --pico-form-element-background-color: #1c212c;
    --pico-form-element-selected-background-color: #2a3140;
    --pico-form-element-border-color: #2a3140;
    --pico-form-element-color: #e0e3e7;
    --pico-form-element-placeholder-color: #8891a4;
    --pico-form-element-active-background-color: #1a1f28;
    --pico-form-element-active-border-color: var(--pico-primary-border);
    --pico-form-element-focus-color: var(--pico-primary-border);
    --pico-form-element-disabled-opacity: .5;
    --pico-form-element-invalid-border-color: #964a50;
    --pico-form-element-invalid-active-border-color: #b7403b;
    --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
    --pico-form-element-valid-border-color: #2a7b6f;
    --pico-form-element-valid-active-border-color: #16896a;
    --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
    --pico-switch-background-color: #333c4e;
    --pico-switch-checked-background-color: var(--pico-primary-background);
    --pico-switch-color: #fff;
    --pico-switch-thumb-box-shadow: 0 0 .5rem #00000040;
    --pico-card-background-color: #181c25;
    --pico-card-border-color: var(--pico-card-background-color);
    --pico-card-box-shadow: var(--pico-box-shadow);
    --pico-card-sectioning-background-color: #1a1f28;
    --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    color-scheme: dark;
  }

  :root:not([data-theme]) input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
    --pico-form-element-focus-color: var(--pico-primary-focus);
  }
}

[data-theme="dark"] {
  --pico-background-color: #13171f;
  --pico-color: #c2c7d0;
  --pico-text-selection-color: #ad9f0030;
  --pico-muted-color: #7b8495;
  --pico-muted-border-color: #202632;
  --pico-primary: #ad9f00;
  --pico-primary-background: #f2df0d;
  --pico-primary-border: var(--pico-primary-background);
  --pico-primary-underline: #ad9f0080;
  --pico-primary-hover: #caba01;
  --pico-primary-hover-background: #f8e861;
  --pico-primary-hover-border: var(--pico-primary-hover-background);
  --pico-primary-hover-underline: var(--pico-primary-hover);
  --pico-primary-focus: #ad9f0060;
  --pico-primary-inverse: #000;
  --pico-secondary: #969eaf;
  --pico-secondary-background: #525f7a;
  --pico-secondary-border: var(--pico-secondary-background);
  --pico-secondary-underline: #969eaf80;
  --pico-secondary-hover: #b3b9c5;
  --pico-secondary-hover-background: #5d6b89;
  --pico-secondary-hover-border: var(--pico-secondary-hover-background);
  --pico-secondary-hover-underline: var(--pico-secondary-hover);
  --pico-secondary-focus: #909ebe40;
  --pico-secondary-inverse: #fff;
  --pico-contrast: #dfe3eb;
  --pico-contrast-background: #eff1f4;
  --pico-contrast-border: var(--pico-contrast-background);
  --pico-contrast-underline: #dfe3eb80;
  --pico-contrast-hover: #fff;
  --pico-contrast-hover-background: #fff;
  --pico-contrast-hover-border: var(--pico-contrast-hover-background);
  --pico-contrast-hover-underline: var(--pico-contrast-hover);
  --pico-contrast-focus: #cfd5e240;
  --pico-contrast-inverse: #000;
  --pico-box-shadow: .0145rem .029rem .174rem #07090c04, .0335rem .067rem .402rem #07090c06, .0625rem .125rem .75rem #07090c08, .1125rem .225rem 1.35rem #07090c09, .2085rem .417rem 2.502rem #07090c0b, .5rem 1rem 6rem #07090c0f, 0 0 0 .0625rem #07090c04;
  --pico-h1-color: #f0f1f3;
  --pico-h2-color: #e0e3e7;
  --pico-h3-color: #c2c7d0;
  --pico-h4-color: #b3b9c5;
  --pico-h5-color: #a4acba;
  --pico-h6-color: #8891a4;
  --pico-mark-background-color: #014063;
  --pico-mark-color: #fff;
  --pico-ins-color: #62af9a;
  --pico-del-color: #ce7e7b;
  --pico-blockquote-border-color: var(--pico-muted-border-color);
  --pico-blockquote-footer-color: var(--pico-muted-color);
  --pico-button-box-shadow: 0 0 0 #0000;
  --pico-button-hover-box-shadow: 0 0 0 #0000;
  --pico-table-border-color: var(--pico-muted-border-color);
  --pico-table-row-stripped-background-color: #6f78870a;
  --pico-form-element-background-color: #1c212c;
  --pico-form-element-selected-background-color: #2a3140;
  --pico-form-element-border-color: #2a3140;
  --pico-form-element-color: #e0e3e7;
  --pico-form-element-placeholder-color: #8891a4;
  --pico-form-element-active-background-color: #1a1f28;
  --pico-form-element-active-border-color: var(--pico-primary-border);
  --pico-form-element-focus-color: var(--pico-primary-border);
  --pico-form-element-disabled-opacity: .5;
  --pico-form-element-invalid-border-color: #964a50;
  --pico-form-element-invalid-active-border-color: #b7403b;
  --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
  --pico-form-element-valid-border-color: #2a7b6f;
  --pico-form-element-valid-active-border-color: #16896a;
  --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
  --pico-switch-background-color: #333c4e;
  --pico-switch-checked-background-color: var(--pico-primary-background);
  --pico-switch-color: #fff;
  --pico-switch-thumb-box-shadow: 0 0 .5rem #00000040;
  --pico-card-background-color: #181c25;
  --pico-card-border-color: var(--pico-card-background-color);
  --pico-card-box-shadow: var(--pico-box-shadow);
  --pico-card-sectioning-background-color: #1a1f28;
  --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  color-scheme: dark;
}

[data-theme="dark"] input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
  --pico-form-element-focus-color: var(--pico-primary-focus);
}

progress, [type="checkbox"], [type="radio"], [type="range"] {
  accent-color: var(--pico-primary);
}

*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  -webkit-tap-highlight-color: transparent;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: var(--pico-font-size);
  line-height: var(--pico-line-height);
  font-family: var(--pico-font-family);
  text-underline-offset: var(--pico-text-underline-offset);
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  cursor: default;
  tab-size: 4;
}

body {
  width: 100%;
  margin: 0;
}

main {
  display: block;
}

body > header, body > main, body > footer {
  padding-block: var(--pico-block-spacing-vertical);
}

section {
  margin-bottom: var(--pico-block-spacing-vertical);
}

.container, .container-fluid {
  padding-right: var(--pico-spacing);
  padding-left: var(--pico-spacing);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container {
    max-width: 510px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 700px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 950px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1200px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1450px;
  }
}

.grid {
  grid-column-gap: var(--pico-grid-column-gap);
  grid-row-gap: var(--pico-grid-row-gap);
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 768px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

.overflow-auto {
  overflow: auto;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

address, blockquote, dl, ol, p, pre, table, ul {
  margin-top: 0;
  margin-bottom: var(--pico-typography-spacing-vertical);
  color: var(--pico-color);
  font-style: normal;
  font-weight: var(--pico-font-weight);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--pico-typography-spacing-vertical);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: var(--pico-font-size);
  line-height: var(--pico-line-height);
  font-family: var(--pico-font-family);
}

h1 {
  --pico-color: var(--pico-h1-color);
}

h2 {
  --pico-color: var(--pico-h2-color);
}

h3 {
  --pico-color: var(--pico-h3-color);
}

h4 {
  --pico-color: var(--pico-h4-color);
}

h5 {
  --pico-color: var(--pico-h5-color);
}

h6 {
  --pico-color: var(--pico-h6-color);
}

:where(article, address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--pico-typography-spacing-top);
}

p, hgroup {
  margin-bottom: var(--pico-typography-spacing-vertical);
}

hgroup > * {
  margin-top: 0;
  margin-bottom: 0;
}

hgroup > :not(:first-child):last-child {
  --pico-color: var(--pico-muted-color);
  --pico-font-weight: unset;
  font-size: 1rem;
}

:where(ol, ul) li {
  margin-bottom: calc(var(--pico-typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--pico-typography-spacing-vertical) * .25);
}

ul li {
  list-style: square;
}

mark {
  background-color: var(--pico-mark-background-color);
  color: var(--pico-mark-color);
  vertical-align: baseline;
  padding: .125rem .25rem;
}

blockquote {
  margin: var(--pico-typography-spacing-vertical) 0;
  padding: var(--pico-spacing);
  border-right: none;
  border-left: .25rem solid var(--pico-blockquote-border-color);
  border-inline-start: .25rem solid var(--pico-blockquote-border-color);
  border-inline-end: none;
  display: block;
}

blockquote footer {
  margin-top: calc(var(--pico-typography-spacing-vertical) * .5);
  color: var(--pico-blockquote-footer-color);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

ins {
  color: var(--pico-ins-color);
  text-decoration: none;
}

del {
  color: var(--pico-del-color);
}

::selection {
  background-color: var(--pico-text-selection-color);
}

:where(a:not([role="button"])), [role="link"] {
  --pico-color: var(--pico-primary);
  --pico-background-color: transparent;
  --pico-underline: var(--pico-primary-underline);
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  -webkit-text-decoration: var(--pico-text-decoration);
  text-decoration: var(--pico-text-decoration);
  -webkit-text-decoration-color: var(--pico-underline);
  text-decoration-color: var(--pico-underline);
  text-underline-offset: .125em;
  transition: background-color var(--pico-transition), color var(--pico-transition), text-decoration var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
}

:where(a:not([role="button"])):is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"]:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-primary-hover);
  --pico-underline: var(--pico-primary-hover-underline);
  --pico-text-decoration: underline;
}

:where(a:not([role="button"])):focus-visible, [role="link"]:focus-visible {
  box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

:where(a:not([role="button"])).secondary, [role="link"].secondary {
  --pico-color: var(--pico-secondary);
  --pico-underline: var(--pico-secondary-underline);
}

:where(a:not([role="button"])).secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"].secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-secondary-hover);
  --pico-underline: var(--pico-secondary-hover-underline);
}

:where(a:not([role="button"])).contrast, [role="link"].contrast {
  --pico-color: var(--pico-contrast);
  --pico-underline: var(--pico-contrast-underline);
}

:where(a:not([role="button"])).contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"].contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-contrast-hover);
  --pico-underline: var(--pico-contrast-hover-underline);
}

a[role="button"] {
  display: inline-block;
}

button {
  text-transform: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

button, [type="submit"], [type="reset"], [type="button"] {
  -webkit-appearance: button;
}

button, [type="submit"], [type="reset"], [type="button"], [role="button"] {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  --pico-color: var(--pico-primary-inverse);
  --pico-box-shadow: var(--pico-button-box-shadow, 0 0 0 #0000);
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: 1rem;
  line-height: var(--pico-line-height);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
  text-decoration: none;
}

[type="file"]::file-selector-button {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  --pico-color: var(--pico-primary-inverse);
  --pico-box-shadow: var(--pico-button-box-shadow, 0 0 0 #0000);
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: 1rem;
  line-height: var(--pico-line-height);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
  text-decoration: none;
}

button[aria-current]:not([aria-current="false"]), button:is(:hover, :active, :focus), [type="submit"][aria-current]:not([aria-current="false"]), [type="submit"]:is(:hover, :active, :focus), [type="reset"][aria-current]:not([aria-current="false"]), [type="reset"]:is(:hover, :active, :focus), [type="button"][aria-current]:not([aria-current="false"]), [type="button"]:is(:hover, :active, :focus), [role="button"][aria-current]:not([aria-current="false"]), [role="button"]:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

[type="file"]::file-selector-button:is() {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

button:focus, button[aria-current]:not([aria-current="false"]):focus, [type="submit"]:focus, [type="submit"][aria-current]:not([aria-current="false"]):focus, [type="reset"]:focus, [type="reset"][aria-current]:not([aria-current="false"]):focus, [type="button"]:focus, [type="button"][aria-current]:not([aria-current="false"]):focus, [role="button"]:focus, [role="button"][aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

[type="file"]::file-selector-button:focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

[type="file"]::file-selector-button:is():focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

[type="submit"], [type="reset"], [type="button"] {
  margin-bottom: var(--pico-spacing);
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary, [type="reset"] {
  --pico-background-color: var(--pico-secondary-background);
  --pico-border-color: var(--pico-secondary-border);
  --pico-color: var(--pico-secondary-inverse);
  cursor: pointer;
}

[type="file"]::file-selector-button {
  --pico-background-color: var(--pico-secondary-background);
  --pico-border-color: var(--pico-secondary-border);
  --pico-color: var(--pico-secondary-inverse);
  cursor: pointer;
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"]:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: var(--pico-secondary-hover-background);
  --pico-border-color: var(--pico-secondary-hover-border);
  --pico-color: var(--pico-secondary-inverse);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-secondary-hover-background);
  --pico-border-color: var(--pico-secondary-hover-border);
  --pico-color: var(--pico-secondary-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary:focus, :is(button, [type="submit"], [type="button"], [role="button"]).secondary[aria-current]:not([aria-current="false"]):focus, [type="reset"]:focus, [type="reset"][aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[type="file"]::file-selector-button:focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[type="file"]::file-selector-button:is():focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast {
  --pico-background-color: var(--pico-contrast-background);
  --pico-border-color: var(--pico-contrast-border);
  --pico-color: var(--pico-contrast-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: var(--pico-contrast-hover-background);
  --pico-border-color: var(--pico-contrast-hover-border);
  --pico-color: var(--pico-contrast-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast:focus, :is(button, [type="submit"], [type="button"], [role="button"]).contrast[aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-contrast-focus);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline, [type="reset"].outline {
  --pico-background-color: transparent;
  --pico-color: var(--pico-primary);
  --pico-border-color: var(--pico-primary);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"].outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: transparent;
  --pico-color: var(--pico-primary-hover);
  --pico-border-color: var(--pico-primary-hover);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.secondary, [type="reset"].outline {
  --pico-color: var(--pico-secondary);
  --pico-border-color: var(--pico-secondary);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"].outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-secondary-hover);
  --pico-border-color: var(--pico-secondary-hover);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.contrast {
  --pico-color: var(--pico-contrast);
  --pico-border-color: var(--pico-contrast);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-contrast-hover);
  --pico-border-color: var(--pico-contrast-hover);
}

:where(button, [type="submit"], [type="reset"], [type="button"], [role="button"])[disabled], :where(fieldset[disabled]) :is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]) {
  opacity: .5;
  pointer-events: none;
}

:where(table) {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
  width: 100%;
}

th, td {
  padding: calc(var(--pico-spacing) / 2) var(--pico-spacing);
  border-bottom: var(--pico-border-width) solid var(--pico-table-border-color);
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  text-align: left;
  text-align: start;
}

tfoot th, tfoot td {
  border-top: var(--pico-border-width) solid var(--pico-table-border-color);
  border-bottom: 0;
}

table.striped tbody tr:nth-child(odd) th, table.striped tbody tr:nth-child(odd) td {
  background-color: var(--pico-table-row-stripped-background-color);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

:where(iframe) {
  border-style: none;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
}

figure figcaption {
  padding: calc(var(--pico-spacing) * .5) 0;
  color: var(--pico-muted-color);
}

hr {
  margin: var(--pico-typography-spacing-vertical) 0;
  border: 0;
  border-top: 1px solid var(--pico-muted-border-color);
  color: inherit;
  height: 0;
}

[hidden], template {
  display: none !important;
}

canvas {
  display: inline-block;
}

input, optgroup, select, textarea {
  font-size: 1rem;
  line-height: var(--pico-line-height);
  letter-spacing: inherit;
  margin: 0;
  font-family: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"], [type="range"] {
  border-width: 0;
  padding: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"]) {
  height: calc(1rem * var(--pico-line-height)  + var(--pico-form-element-spacing-vertical) * 2 + var(--pico-border-width) * 2);
}

fieldset {
  margin: 0;
  margin-bottom: var(--pico-spacing);
  border: 0;
  width: 100%;
  padding: 0;
}

label, fieldset legend {
  margin-bottom: calc(var(--pico-spacing) * .375);
  color: var(--pico-color);
  font-weight: var(--pico-form-label-font-weight, var(--pico-font-weight));
  display: block;
}

fieldset legend {
  margin-bottom: calc(var(--pico-spacing) * .5);
}

input:not([type="checkbox"], [type="radio"]), button[type="submit"], select, textarea {
  width: 100%;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), select, textarea {
  appearance: none;
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
}

input, select, textarea {
  --pico-background-color: var(--pico-form-element-background-color);
  --pico-border-color: var(--pico-form-element-border-color);
  --pico-color: var(--pico-form-element-color);
  --pico-box-shadow: none;
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
}

input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [readonly]):is(:active, :focus), :where(select, textarea):not([readonly]):is(:active, :focus) {
  --pico-background-color: var(--pico-form-element-active-background-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [role="switch"], [readonly]):is(:active, :focus), :where(select, textarea):not([readonly]):is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-active-border-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [type="range"], [type="file"], [readonly]):focus, :where(select, textarea):not([readonly]):focus {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-focus-color);
}

input:not([type="submit"], [type="button"], [type="reset"])[disabled], select[disabled], textarea[disabled], label[aria-disabled="true"], :where(fieldset[disabled]) :is(input:not([type="submit"], [type="button"], [type="reset"]), select, textarea) {
  opacity: var(--pico-form-element-disabled-opacity);
  pointer-events: none;
}

label[aria-disabled="true"] input[disabled] {
  opacity: 1;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid] {
  padding-left: var(--pico-form-element-spacing-horizontal);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem) !important;
  padding-inline-start: var(--pico-form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem) !important;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid="false"]:not(select) {
  background-image: var(--pico-icon-valid);
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid="true"]:not(select) {
  background-image: var(--pico-icon-invalid);
}

:where(input, select, textarea)[aria-invalid="false"] {
  --pico-border-color: var(--pico-form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-valid-active-border-color) !important;
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus):not([type="checkbox"], [type="radio"]) {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"] {
  --pico-border-color: var(--pico-form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-invalid-active-border-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus):not([type="checkbox"], [type="radio"]) {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-invalid-focus-color) !important;
}

[dir="rtl"] :where(input, select, textarea):not([type="checkbox"], [type="radio"]):is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: .75rem 50%;
}

input::placeholder, textarea::placeholder, select:invalid {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  margin-bottom: var(--pico-spacing);
}

select::-ms-expand {
  background-color: #0000;
  border: 0;
}

select:not([multiple], [size]) {
  padding-right: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem);
  padding-left: var(--pico-form-element-spacing-horizontal);
  background-image: var(--pico-icon-chevron);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: var(--pico-form-element-spacing-horizontal);
  padding-inline-end: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem);
}

select[multiple] option:checked {
  background: var(--pico-form-element-selected-background-color);
}

[dir="rtl"] select:not([multiple], [size]) {
  background-position: .75rem 50%;
}

textarea {
  resize: vertical;
  display: block;
}

textarea[aria-invalid] {
  --pico-icon-height: calc(1rem * var(--pico-line-height)  + var(--pico-form-element-spacing-vertical) * 2 + var(--pico-border-width) * 2);
  background-position: right .75rem top !important;
  background-size: 1rem var(--pico-icon-height) !important;
}

:where(input, select, textarea, fieldset, .grid) + small {
  margin-top: calc(var(--pico-spacing) * -.75);
  margin-bottom: var(--pico-spacing);
  color: var(--pico-muted-color);
  width: 100%;
  display: block;
}

:where(input, select, textarea, fieldset, .grid)[aria-invalid="false"] + small {
  color: var(--pico-ins-color);
}

:where(input, select, textarea, fieldset, .grid)[aria-invalid="true"] + small {
  color: var(--pico-del-color);
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--pico-spacing) * .25);
}

label:has([type="checkbox"], [type="radio"]) {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  margin-top: -.125em;
  border-width: var(--pico-border-width);
  vertical-align: middle;
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  margin-inline-end: .5em;
}

[type="checkbox"]::-ms-check {
  display: none;
}

[type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked, [type="checkbox"]:checked:active, [type="checkbox"]:checked:focus, [type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  background-image: var(--pico-icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="checkbox"] ~ label, [type="radio"] ~ label {
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

[type="checkbox"] ~ label:not(:last-of-type), [type="radio"] ~ label:not(:last-of-type) {
  margin-inline-end: 1em;
}

[type="checkbox"]:indeterminate {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  background-image: var(--pico-icon-minus);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --pico-background-color: var(--pico-primary-inverse);
  background-image: none;
  border-width: .35em;
}

[type="checkbox"][role="switch"] {
  --pico-background-color: var(--pico-switch-background-color);
  --pico-color: var(--pico-switch-color);
  border: var(--pico-border-width) solid var(--pico-border-color);
  background-color: var(--pico-background-color);
  border-radius: 1.25em;
  width: 2.25em;
  height: 1.25em;
  line-height: 1.25em;
}

[type="checkbox"][role="switch"]:not([aria-invalid]) {
  --pico-border-color: var(--pico-switch-background-color);
}

[type="checkbox"][role="switch"]:before {
  width: calc(1.25em - var(--pico-border-width) * 2);
  background-color: var(--pico-color);
  box-shadow: var(--pico-switch-thumb-box-shadow);
  content: "";
  border-radius: 50%;
  height: 100%;
  transition: margin .1s ease-in-out;
  display: block;
}

[type="checkbox"][role="switch"]:focus {
  --pico-background-color: var(--pico-switch-background-color);
  --pico-border-color: var(--pico-switch-background-color);
}

[type="checkbox"][role="switch"]:checked {
  --pico-background-color: var(--pico-switch-checked-background-color);
  --pico-border-color: var(--pico-switch-checked-background-color);
  background-image: none;
}

[type="checkbox"][role="switch"]:checked:before {
  margin-inline-start: calc(1.125em - var(--pico-border-width));
}

[type="checkbox"][role="switch"][disabled] {
  --pico-background-color: var(--pico-border-color);
}

[type="checkbox"][aria-invalid="false"]:checked, [type="checkbox"][aria-invalid="false"]:checked:active, [type="checkbox"][aria-invalid="false"]:checked:focus, [type="checkbox"][role="switch"][aria-invalid="false"]:checked, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:active, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:focus {
  --pico-background-color: var(--pico-form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"]:checked:active[aria-invalid="true"], [type="checkbox"]:checked:focus[aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:active[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:focus[aria-invalid="true"] {
  --pico-background-color: var(--pico-form-element-invalid-border-color);
}

[type="checkbox"][aria-invalid="false"]:checked, [type="checkbox"][aria-invalid="false"]:checked:active, [type="checkbox"][aria-invalid="false"]:checked:focus, [type="radio"][aria-invalid="false"]:checked, [type="radio"][aria-invalid="false"]:checked:active, [type="radio"][aria-invalid="false"]:checked:focus, [type="checkbox"][role="switch"][aria-invalid="false"]:checked, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:active, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:focus {
  --pico-border-color: var(--pico-form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"]:checked:active[aria-invalid="true"], [type="checkbox"]:checked:focus[aria-invalid="true"], [type="radio"]:checked[aria-invalid="true"], [type="radio"]:checked:active[aria-invalid="true"], [type="radio"]:checked:focus[aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:active[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:focus[aria-invalid="true"] {
  --pico-border-color: var(--pico-form-element-invalid-border-color);
}

article {
  margin-bottom: var(--pico-block-spacing-vertical);
  padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
  border-radius: var(--pico-border-radius);
  background: var(--pico-card-background-color);
  box-shadow: var(--pico-card-box-shadow);
}

article > header, article > footer {
  margin-right: calc(var(--pico-block-spacing-horizontal) * -1);
  margin-left: calc(var(--pico-block-spacing-horizontal) * -1);
  padding: calc(var(--pico-block-spacing-vertical) * .66) var(--pico-block-spacing-horizontal);
  background-color: var(--pico-card-sectioning-background-color);
}

article > header {
  margin-top: calc(var(--pico-block-spacing-vertical) * -1);
  margin-bottom: var(--pico-block-spacing-vertical);
  border-bottom: var(--pico-border-width) solid var(--pico-card-border-color);
  border-top-right-radius: var(--pico-border-radius);
  border-top-left-radius: var(--pico-border-radius);
}

article > footer {
  margin-top: var(--pico-block-spacing-vertical);
  margin-bottom: calc(var(--pico-block-spacing-vertical) * -1);
  border-top: var(--pico-border-width) solid var(--pico-card-border-color);
  border-bottom-right-radius: var(--pico-border-radius);
  border-bottom-left-radius: var(--pico-border-radius);
}

[role="search"], [role="group"] {
  margin-bottom: var(--pico-spacing);
  border-radius: var(--pico-border-radius);
  box-shadow: var(--pico-group-box-shadow, 0 0 0 #0000);
  vertical-align: middle;
  transition: box-shadow var(--pico-transition);
  width: 100%;
  display: inline-flex;
  position: relative;
}

[role="search"] > *, [role="search"] input:not([type="checkbox"], [type="radio"]), [role="search"] select, [role="group"] > *, [role="group"] input:not([type="checkbox"], [type="radio"]), [role="group"] select {
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

[role="search"] > :not(:first-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="search"] select:not(:first-child), [role="group"] > :not(:first-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="group"] select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

[role="search"] > :not(:last-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:last-child), [role="search"] select:not(:last-child), [role="group"] > :not(:last-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:last-child), [role="group"] select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

[role="search"] > :focus, [role="search"] input:not([type="checkbox"], [type="radio"]):focus, [role="search"] select:focus, [role="group"] > :focus, [role="group"] input:not([type="checkbox"], [type="radio"]):focus, [role="group"] select:focus {
  z-index: 2;
}

[role="search"] button:not(:first-child), [role="search"] [type="submit"]:not(:first-child), [role="search"] [type="reset"]:not(:first-child), [role="search"] [type="button"]:not(:first-child), [role="search"] [role="button"]:not(:first-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="search"] select:not(:first-child), [role="group"] button:not(:first-child), [role="group"] [type="submit"]:not(:first-child), [role="group"] [type="reset"]:not(:first-child), [role="group"] [type="button"]:not(:first-child), [role="group"] [role="button"]:not(:first-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="group"] select:not(:first-child) {
  margin-left: calc(var(--pico-border-width) * -1);
}

[role="search"] button, [role="search"] [type="submit"], [role="search"] [type="reset"], [role="search"] [type="button"], [role="search"] [role="button"], [role="group"] button, [role="group"] [type="submit"], [role="group"] [type="reset"], [role="group"] [type="button"], [role="group"] [role="button"] {
  width: auto;
}

@supports selector(:has(*)) {
  [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus), [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) {
    --pico-group-box-shadow: var(--pico-group-box-shadow-focus-with-button);
  }

  [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) input:not([type="checkbox"], [type="radio"]), [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) select, [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) input:not([type="checkbox"], [type="radio"]), [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) select {
    border-color: #0000;
  }

  [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus), [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) {
    --pico-group-box-shadow: var(--pico-group-box-shadow-focus-with-input);
  }

  [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) button, [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="submit"], [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="button"], [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [role="button"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) button, [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="submit"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="button"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [role="button"] {
    --pico-button-box-shadow: 0 0 0 var(--pico-border-width) var(--pico-primary-border);
    --pico-button-hover-box-shadow: 0 0 0 var(--pico-border-width) var(--pico-primary-hover-border);
  }

  [role="search"] button:focus, [role="search"] [type="submit"]:focus, [role="search"] [type="reset"]:focus, [role="search"] [type="button"]:focus, [role="search"] [role="button"]:focus, [role="group"] button:focus, [role="group"] [type="submit"]:focus, [role="group"] [type="reset"]:focus, [role="group"] [type="button"]:focus, [role="group"] [role="button"]:focus {
    box-shadow: none;
  }
}

[role="search"] > :first-child {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

[role="search"] > :last-child {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

:where(nav li):before {
  float: left;
  content: "​";
}

nav, nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
  overflow: visible;
}

nav ol, nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-left: calc(var(--pico-nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-right: calc(var(--pico-nav-element-spacing-horizontal) * -1);
}

nav li {
  padding: var(--pico-nav-element-spacing-vertical) var(--pico-nav-element-spacing-horizontal);
  margin: 0;
  display: inline-block;
}

nav li :where(a, [role="link"]) {
  margin: calc(var(--pico-nav-link-spacing-vertical) * -1) calc(var(--pico-nav-link-spacing-horizontal) * -1);
  padding: var(--pico-nav-link-spacing-vertical) var(--pico-nav-link-spacing-horizontal);
  border-radius: var(--pico-border-radius);
  display: inline-block;
}

nav li :where(a, [role="link"]):not(:hover) {
  text-decoration: none;
}

nav li button, nav li [role="button"], nav li input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), nav li select {
  margin-right: inherit;
  margin-bottom: 0;
  margin-left: inherit;
  padding: calc(var(--pico-nav-link-spacing-vertical)  - var(--pico-border-width) * 2) var(--pico-nav-link-spacing-horizontal);
  height: auto;
}

nav[aria-label="breadcrumb"] {
  justify-content: start;
  align-items: center;
}

nav[aria-label="breadcrumb"] ul li:not(:first-child) {
  margin-inline-start: var(--pico-nav-link-spacing-horizontal);
}

nav[aria-label="breadcrumb"] ul li a {
  margin: calc(var(--pico-nav-link-spacing-vertical) * -1) 0;
  margin-inline-start: calc(var(--pico-nav-link-spacing-horizontal) * -1);
}

nav[aria-label="breadcrumb"] ul li:not(:last-child):after {
  width: calc(var(--pico-nav-link-spacing-horizontal) * 4);
  margin: 0 calc(var(--pico-nav-link-spacing-horizontal) * -1);
  content: var(--pico-nav-breadcrumb-divider);
  color: var(--pico-muted-color);
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  position: absolute;
}

nav[aria-label="breadcrumb"] a[aria-current]:not([aria-current="false"]) {
  color: inherit;
  pointer-events: none;
  background-color: #0000;
  text-decoration: none;
}

aside nav, aside ol, aside ul, aside li {
  display: block;
}

aside li {
  padding: calc(var(--pico-nav-element-spacing-vertical) * .5) var(--pico-nav-element-spacing-horizontal);
}

aside li a {
  display: block;
}

aside li [role="button"] {
  margin: inherit;
}

[dir="rtl"] nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  content: "\\";
}

:root {
  --pico-border-radius: 0;
  --md: 768px;
  --text-block-gradient: linear-gradient(155deg, #dedede33 0%, #dedede00 100%);
}

html {
  scroll-behavior: smooth;
}

[data-theme="light"], :root:not([data-theme="dark"]) {
  --pico-background-color: #fefefe;
  --pico-box-shadow: 1px 1px 3px #00000030, 2px 3px 5px 1px #00000020;
  --pico-blockquote-border-color: var(--pico-primary-background);
  --pico-h3-color: #717171;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("inter-latin-900-normal.f4f1d2ab.woff2") format("woff2");
}

@font-face {
  font-family: Roit;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("protest-riot-latin-400-normal.2ac2413a.woff2") format("woff2");
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.text-box, section.leistungen .text {
  z-index: 1;
  background: var(--text-block-gradient);
  padding: 1.5rem;
  padding-top: 2.5rem;
  position: relative;
}

.text-box:after, section.leistungen .text:after {
  content: "";
  z-index: 1;
  background: var(--pico-primary-background);
  width: 20%;
  height: .4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

h1, h2 {
  --pico-font-family: "Inter", sans-serif;
  --pico-font-weight: 900;
}

@media (width >= 768px) {
  h1 {
    --pico-font-size: 2.5rem;
  }
}

h2 {
  --pico-font-size: 2rem;
  text-align: center;
  margin-bottom: 3rem;
  padding-top: 3rem;
}

h2 a {
  --pico-color: var(--pico-primary-background);
  --pico-primary-hover: var(--pico-primary-background);
  opacity: .25;
  --pico-text-decoration: none !important;
}

h2:hover a {
  opacity: 1;
}

h3 {
  --pico-font-weight: 400;
  font-family: Roit;
}

blockquote {
  background: var(--text-block-gradient);
  border-left-width: .4rem;
}

svg.bi {
  width: 1em;
  height: 1em;
}

body[is-opened] {
  overflow: hidden;
}

.page-wrap {
  z-index: 2;
  position: relative;
}

header.page-header {
  --pico-block-spacing-vertical: 0;
  background-color: var(--pico-secondary);
  background-image: url("markus-spiske-d7FbDJkJSFw-unsplash_hd.1147b54a.webp");
  background-size: cover;
  background-attachment: fixed;
  height: 35rem;
}

header.page-header > div {
  background-color: var(--pico-background-color);
}

header.page-header nav {
  width: calc(100% - 3.5rem);
  margin: auto;
}

header.page-header nav a {
  --pico-font-family: "Inter", sans-serif;
}

header.page-header h1 {
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  -webkit-text-decoration-color: var(--pico-primary-background);
  text-decoration-color: var(--pico-primary-background);
  text-underline-offset: 1rem;
  padding: 0 0 3rem;
  line-height: 4.5rem;
  text-decoration-thickness: .3rem;
}

header.page-header .logo {
  padding: 1rem 0 0;
}

header.page-header .logo img {
  height: 6.5rem;
}

header nav.fixed-nav .logo {
  z-index: 100;
  display: none;
  position: fixed;
}

[is-opened] nav.fixed-nav .logo {
  opacity: 0;
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  display: flex;
}

#menu {
  z-index: -1;
  background: var(--pico-background-color);
  border-radius: 100% 0 100% 100%;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  transition: border-radius .5s, transform .3s;
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100vw, -100vh);
}

#menu .item {
  opacity: 0;
  transition: transform .8s ease-out .3s, opacity .5s ease-out .3s;
  transform: translateX(-25%);
}

[is-opened] #menu {
  z-index: 99;
  border-radius: 0;
  transform: translate(0);
}

[is-opened] #menu .item {
  opacity: 1;
  transform: translateX(0);
}

#menu-btn {
  z-index: 100;
  position: fixed;
  top: 0;
  left: calc(90% - 1.85rem);
}

#menu-btn button {
  font-size: 1.35rem;
}

#menu-btn svg {
  width: 1em;
  height: 1em;
  transition: transform .3s;
  transform: rotate(136deg);
}

[is-opened] #menu-btn svg {
  width: 1em;
  transform: rotate(-45deg);
}

main {
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  overflow: hidden;
}

main > section:last-child {
  margin-bottom: 0;
}

section.leistungen > div {
  gap: 2rem;
  margin-bottom: 3.5rem;
  display: flex;
}

@media (width <= 768px) {
  section.leistungen > div {
    display: block;
  }
}

section.leistungen .img {
  z-index: 1;
  flex: 1;
  position: relative;
}

section.leistungen .img img {
  margin: auto;
  display: block;
  position: relative;
}

section.leistungen .img:after {
  content: "";
  z-index: -1;
  border: 3px dotted var(--pico-primary-background);
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
  display: block;
  position: absolute;
  top: -4rem;
  left: -2rem;
}

section.leistungen .text {
  flex: 1.68 1 0;
}

section.partner .grid > div {
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

section.about {
  margin-bottom: 7rem;
}

section.map h2 {
  text-align: center;
  width: 100%;
  margin: auto;
  padding-top: 3rem;
  position: absolute;
}

section.map img {
  margin: auto;
  display: block;
}

footer.page-footer {
  z-index: -1;
  background-color: var(--pico-secondary);
  text-align: center;
  background-image: url("markus-spiske-d7FbDJkJSFw-unsplash_hd.1147b54a.webp");
  background-size: cover;
  padding: 3rem 0;
  position: sticky;
  bottom: 0;
}

footer.page-footer article {
  display: inline-block;
}

footer.page-footer h2 {
  padding-top: unset;
}

footer.page-footer .media {
  justify-content: space-evenly;
  margin-bottom: .5rem;
  font-size: 1.35rem;
  display: flex;
}

[data-inviewport="slide-left"] {
  transition: transform .5s ease-in-out, opacity .5s ease-in;
}

[data-inviewport="slide-left"].inViewport-init {
  opacity: 0;
  transform: translateX(-18px);
}

[data-inviewport="slide-left"].is-inViewport {
  opacity: 1;
  transform: translateX(1px);
}

[data-inviewport="slide-right"] {
  transition: transform .5s ease-in-out, opacity .5s ease-in;
}

[data-inviewport="slide-right"].inViewport-init {
  opacity: 0;
  transform: translateX(18px);
}

[data-inviewport="slide-right"].is-inViewport {
  opacity: 1;
  transform: translateX(1px);
}

/*# sourceMappingURL=index.8845481f.css.map */
